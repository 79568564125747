<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="角色ID">
                <a-input placeholder="请输入角色ID" v-model="queryParam.roleId" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="角色名称">
                <a-input placeholder="请输入角色名称" v-model="queryParam.roleName" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <a-form-item label="状态">
                <a-select v-model="queryParam.status" placeholder="请选择状态">
                  <a-select-option v-for="item in conditionlist.statusList" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetSearchForm()">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handleAdd">添加</a-button>
      </div>
      <s-table
        ref="table"
        size="default"
        rowKey="roleId"
        :columns="columns"
        :data="loadData"
      >
        <span slot="status" slot-scope="text">
          <a-badge :status="text | statusTypeFilter" :text="text | statusFilter" />
        </span>
        <span slot="roleBrief" slot-scope="text">
          <ellipsis :length="24" tooltip>{{ text }}</ellipsis>
        </span>
        <div
          slot="expandedRowRender"
          slot-scope="record"
          style="margin: 0">
          <a-row
            :gutter="24"
            v-for="(role, index) in record.routeList"
            :key="index"
          >
            <a-col :span="6" v-for="(role1, index1) in role" :key="index1" :style="{ marginBottom: '12px' }">
              <a-tree
                :defaultExpandAll="true"
                :showLine="true"
                :treeData="[role1]"
              >
              </a-tree>
            </a-col>
            <a-divider :dashed="true" v-if="(index+1) !== record.routeList.length"></a-divider>
            <!--a-col :span="12" v-for="(role, index) in record.permissions" :key="index" :style="{ marginBottom: '12px' }">
              <a-col :span="4">
                <span>{{ role.permissionName }}：</span>
              </a-col>
              <a-col :span="20" v-if="role.actionEntitySet.length > 0">
                <a-tag color="cyan" v-for="(action, k) in role.actionEntitySet" :key="k">{{ action.describe }}</a-tag>
              </a-col>
              <a-col :span="20" v-else>-</a-col>
            </a-col-->
          </a-row>
        </div>
        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a @click="setRoleStatusApi(record.roleId, 1)" v-if="record.status === 0" :disabled="(record.isBaseRole === 1) ? true : false">禁用</a>
          <a @click="setRoleStatusApi(record.roleId, 0)" v-else :disabled="(record.isBaseRole === 1) ? true : false">启用</a>
          <a-divider type="vertical" />
          <a @click="gotoModulePower(record.roleId)">功能权限</a>
          <a-divider type="vertical" />
          <a @click="gotoRegionPower(record.roleId)">区域权限</a>
          <a-divider type="vertical" />
          <a @click="gotoUserList(record.roleId)">查看员工</a>
        </span>
      </s-table>
      <a-modal
        title="添加/编辑角色"
        :width="800"
        :destroyOnClose="true"
        v-model="visible"
        @ok="saveRole"
      >
        <a-form :form="form">
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="角色"
          >
            <a-input placeholder="请输入网格角色名称" v-model="mdl.roleName" />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="状态"
          >
            <a-radio-group v-model="mdl.status" style="width: 100%" :disabled="(mdl.defaultRoleId === 1) ? true : false">
              <a-radio :value="0">正常</a-radio>
              <a-radio :value="1">禁用</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="角色简介"
          >
            <a-textarea :rows="5" v-model="mdl.roleBrief" placeholder="角色简介" />
          </a-form-item>
        </a-form>
      </a-modal>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import STree from '@/components/Tree/Tree'
import { STable, Ellipsis } from '@/components'
import { Modal } from 'ant-design-vue'
import { gridAddRole, gridRoleCondition, gridRoleList, setGridRoleStatus } from '@/api/gridrole'
const columns = [
  {
    title: '角色ID',
    dataIndex: 'roleId'
  },
  {
    title: '角色名称',
    dataIndex: 'roleName'
  },
  {
    title: '角色简介',
    dataIndex: 'roleBrief',
    scopedSlots: { customRender: 'roleBrief' }
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    title: '更新时间',
    width: '200px',
    dataIndex: 'updateTimeDate'
  },
  {
    title: '操作',
    width: '350px',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

const statusMap = {
  0: {
    status: 'success',
    text: '正常'
  },
  1: {
    status: 'default',
    text: '禁用'
  }
}
export default {
  name: 'TableList',
  components: {
    STable,
    Ellipsis,
    STree
  },
  data () {
    this.columns = columns
    return {
      description: '',
      visible: false,
      form: this.$form.createForm(this, { name: 'addRoleFrom' }),
      mdl: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },

      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {
        roleName: '',
        roleId: '',
        status: 9
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        return gridRoleList(requestParameters)
          .then(res => {
            return res.result
          })
      },
      selectedRowKeys: [],
      selectedRows: [],
      conditionlist: {
        statusList: []
      },
      roleParam: {
        roleId: 0
      }
    }
  },
  filters: {
    statusFilter (type) {
      return statusMap[type].text
    },
    statusTypeFilter (type) {
      return statusMap[type].status
    }
  },
  created () {
    this.roleConditionApi()
  },
  methods: {
    setRoleStatusApi (roleId, status) {
      const _this = this
      var title = (status === 1) ? '禁用角色' : '启用角色'
      var content = (status === 1) ? '确认要禁用该角色吗？' : '确认要启用该角色吗？'
      Modal.confirm({
        title: title,
        content: content,
        onOk: () => {
          setGridRoleStatus({ roleId: roleId, status: status }).then((res) => {
            if (res.errorCode === 0) {
              _this.$message.info(res.errorMsg)
              _this.$refs.table.refresh()
            } else {
              _this.$message.error(res.errorMsg)
            }
          }).catch((err) => {
            _this.$message.error('系统错误')
            console.log(err)
          })
        },
        onCancel () {
          console.log('onCancel')
        }
      })
    },
    roleConditionApi () {
      const _this = this
      gridRoleCondition({}).then((res) => {
        if (res.errorCode === 0) {
          _this.conditionlist = res.result
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    resetSearchForm () {
      this.queryParam = {
        roleName: '',
        roleId: '',
        status: 9
      }
    },
    saveRole () {
      const _this = this
      _this.roleParam.roleId = _this.mdl.roleId ? _this.mdl.roleId : 0
      _this.roleParam.roleName = _this.mdl.roleName
      _this.roleParam.status = _this.mdl.status
      _this.roleParam.roleBrief = _this.mdl.roleBrief
      gridAddRole(_this.roleParam).then((res) => {
        if (res.errorCode === 0) {
          if (_this.roleParam.roleId > 0) {
            _this.$message.success('更新成功')
          } else {
            _this.$message.success('添加成功')
          }
          _this.visible = false
          _this.$refs.table.refresh()
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    handleAdd () {
      this.mdl = {
        roleId: 0,
        roleName: '',
        roleBrief: '',
        status: 0,
        defaultRoleId: 0
      }
      this.visible = true
    },
    handleEdit (record) {
      this.mdl = Object.assign({}, record)
      console.log(this.mdl)
      this.visible = true
    },
    handleOk () {
      // 新增/修改 成功时，重载列表
      this.$refs.table.refresh()
    },
    onChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    gotoModulePower (roleId) {
      this.$router.push({ path: '/gridrole/module-power?roleId=' + roleId })
    },
    gotoRegionPower (roleId) {
      this.$router.push({ path: '/gridrole/region-power?roleId=' + roleId })
    },
    gotoUserList (roleId) {
      this.$router.push({ path: '/syueuser/user-list?roleId=' + roleId })
    }
  },
  watch: {
    /*
      'selectedRows': function (selectedRows) {
        this.needTotalList = this.needTotalList.map(item => {
          return {
            ...item,
            total: selectedRows.reduce( (sum, val) => {
              return sum + val[item.dataIndex]
            }, 0)
          }
        })
      }
      */
  }
}
</script>
